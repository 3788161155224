import * as React from "react"
import Menu from '../../components/menu'
import Footer from '../../components/footer'
import styled from 'styled-components'
import StyledLink from '../../components/styledLink'
import '../../styles/typography.css'
import SubPageHero from "../../components/sub-page-hero"
import squiggles from '../../images/squiggles.svg'
import clock from '../../images/clock-icon.svg'
import euro  from '../../images/euro-icon.svg'
import HelmetInfo from '../../components/helmet-info'
import 'animate.css'

const SquigglesImage = () => {
    return <img className="hero-squiggles" src={squiggles} alt="decorative squiggles"/>
}

const ServiceWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;

    h2, p {
        text-align: left;
    }
    .inlineLink { 
        margin-left: 20px;
        margin-top: 10px;
    }
  
    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }
`

const ServiceList = () => {
    return(
        <ServiceWrapper>
            
 
            <div className="service">
                <h2>What are educational videos and why use them?</h2>
                <p>Educational videos are designed to impart knowledge on the viewer. Most Educational videos convey their information via imagery and voice over. Some use screen recordings mixed with animated design elements.</p>
                <p>Educational videos often contain complex information displayed in easily digestable infographics. They can also serve to explain to a customer how a particular aspect of your business works.</p> 
            </div>
            <div className="service">
                <h2>When and how should I use an educational video?</h2>
                <p>When you want to teach someone a new skill (training staff members for example) or inform them about an important topic. Educational videos can serve as part of online courses or as part of an advertising campaign that requires awareness to be raised around a topic of interest. Educational videos can serve as part of a blogging platforms content or as part your marketing content strategy.</p>
                <StyledLink className='inlineLink' to={'../../contact-us'}>Get in touch</StyledLink>
                <SquigglesImage/>

        </div>
    </ServiceWrapper>
    )
}

const ServicePriceListWrapper = styled.div`
    background: #e6e6e6;
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
`

const ServicePriceListGrid = styled.div`
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px;

    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }

    .prices-icon {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        @media screen and (min-width: 900px) {
            grid-template-columns: 146px 1fr;
        }

        img {
            max-width: 146px;
            margin: 0 20px 20px 20px;

            @media screen and (min-width: 900px) {
                margin: 0;
            }
         
        }
        
        p { 
            text-align: left; 
        }

        h2 {
            text-align: left; 
            font-size: 2rem;
        }
    }
`

const ServicePriceListItem = ({image, title, text}) => {
  return(
    <div className="prices-icon">
        <img src={image} alt=""/>
        <div className="prices-info">
            <h2>{title}</h2> 
            <p>{text}</p>
        </div>
    </div>
  )   
}

const ServicePriceList = () => {
    return (
    <>

        <ServicePriceListWrapper>
            <ServicePriceListGrid>

                <ServicePriceListItem 
                    image={clock} 
                    title={'How long does an educational video take to produce?'}
                    text={'Educational videos normally take between 6-8 weeks to produce. If clients have extensive tweaks or changes it can take up to 12 weeks.'}
                />

                <ServicePriceListItem 
                    image={euro} 
                    title={'How much does an educational video cost?'}
                    text={'Educational videos generally cost between €4,000 and €8,000. Though the budget can vary depending on the project.'}
                />

            </ServicePriceListGrid>
        </ServicePriceListWrapper>

        <FullWidth>
            <StyledLink to="../">Our Services</StyledLink>  
        </FullWidth>
    </>

        
    )
}

const FullWidth = styled.div`
    background-color: black;
    padding: 50px;
    padding-top: 10px;
`

const Shorts = () => {
    return(
        <>
        <HelmetInfo />
        <Menu/>
        <SubPageHero>
            <h1 className="animate__animated animate__bounceInDown">Animated Shorts</h1>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
        </SubPageHero>
        <ServiceList />
        <ServicePriceList />
       
        <Footer />
        </>
    )
}

export default Shorts